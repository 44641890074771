import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  header: PropTypes.string,
  content: PropTypes.string,
  doesNotEnd: PropTypes.bool,
  pageDisplayedOn: PropTypes.array,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  header: '',
  content: '',
  doesNotEnd: false,
  pageDisplayedOn: [],
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  return errors;
};

// pageDisplayedOnOptions [id, label]
const pageDisplayedOnOptions = [
  ['aaa', 'AAA'],
  ['accessible-features', 'Accessible Features'],
  ['amenities', 'Amenities Overview'],
  ['business', 'Business Travel'],
  ['homepage', 'Home Page'],
  ['map-directions', 'Map + Directions'],
  ['meetings', 'Meetings Overview'],
  ['packages-promotions', 'Packages + Promotions'],
  ['things-to-do', ' Things to Do Overview'],
  ['weddings', 'Weddings Overview']
];

const HotelListForm = ({
  id,
  handleSubmit,
  header,
  content,
  doesNotEnd,
  pageDisplayedOn,
  published,
  archived,
  change,
  error
}) => {
  const contentHtml = content || '';
  const setPageDisplayedOn = (v, rem) => {
    if (!pageDisplayedOn) {
      /* eslint-disable no-param-reassign */
      pageDisplayedOn = [];
    }
    const i = pageDisplayedOn.indexOf(v);
    if (!rem && i !== -1) {
      pageDisplayedOn.splice(i, 1);
    } else {
      pageDisplayedOn.push(v);
    }
    change('pageDisplayedOn', [...new Set(pageDisplayedOn)]);
  };
  const checkPageDisplayedOn = v => {
    if (!pageDisplayedOn) {
      return false;
    }

    return pageDisplayedOn.indexOf(v) > -1;
  };

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the title and content to use for this List.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Title*</div>
          <Field
            id="lists-title"
            name="title"
            className="w-full mb-4"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="mb-3">Header</div>
          <Field
            id="lists-header"
            name="header"
            className="w-full mb-4"
            placeholder="Enter a header..."
            component={Input}
          />
          <div className="mb-3">Content for this list*</div>
          <Field
            id="lists-content"
            name="content"
            className="mb-6"
            placeholder="Enter a short description for this list..."
            component={TinyMCE}
          />
          <div className="mb-12">
            <div className="mb-4">Page Displayed On:</div>
            {pageDisplayedOnOptions.map(v => (
              <div className="mb-3">
                <label className="flex">
                  <Input
                    className="mr-2"
                    id={`pageDisplayedOn${v[0]}`}
                    type="checkbox"
                    input={{
                      checked: checkPageDisplayedOn(v[0]),
                      onChange: (_, val) => {
                        setPageDisplayedOn(v[0], val);
                      }
                    }}
                  />
                  {v[1]}
                </label>
              </div>
            ))}
          </div>
        </div>
      </FormSection>

      <FormSection
        id="date"
        title="Date & Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this List. To set an End Date, uncheck
                'List does not end'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="starts-on"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center mb-6">
            <Field
              id="does-not-end"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="">List does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('mb-3', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="ends-on"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your List. When you're ready, toggle the
                  publish status to display it on the live site and hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">Preview:</div>
            <div className="p-6 preview-list-text">
              <h3>{header}</h3>
              <div dangerouslySetInnerHTML={{ __html: contentHtml }} />
            </div>
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this List?
              </div>
              <Field
                id="archived"
                name="archived"
                component={Switch}
                label={archived ? 'Archived' : 'Unarchived'}
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-list-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelListForm.propTypes = propTypes;
HotelListForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelListForm');

export default compose(
  reduxForm({ form: 'HotelListForm', validate }),
  connect(state => ({
    title: selector(state, 'title'),
    header: selector(state, 'header'),
    content: selector(state, 'content'),
    pageDisplayedOn: selector(state, 'pageDisplayedOn'),
    doesNotEnd: selector(state, 'doesNotEnd'),
    published: selector(state, 'published'),
    archived: selector(state, 'archived'),
    profilePermissions: state.auth.permissions
  }))
)(HotelListForm);
